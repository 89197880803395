import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

import Routes from '../components/Routes'

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
  })
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Routes />,
    document.body.appendChild(document.createElement('div'))
  )
})
